import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import ContentWithImage from "../components/ContentWithImage";
import ContentWithSlider from "../components/ContentWithSlider";
import Testimonials from "../components/Testimonials";
import SliderSection from "../components/SliderSection";
import PageLinks from "../components/PageLinks";

export const query = graphql`
  {
    wpPage(slug: { eq: "home" }) {
      id
      title
      content
      pageSections {
        sections {
          __typename
          ...HeroSection
          ...WpContent
          ...WpContentSlider
          ...SliderSection
          ...Testimonials
        }
      }
      pageLinks {
        ...WpPage_PagelinksFragment
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const page = data.wpPage;
  const sections = data.wpPage.pageSections.sections;

  return (
    <Layout title={page.title}>
      <main>
        {sections.map((section, index) => {
          const typeName = section.__typename;

          switch (typeName) {
            case "WpPage_Pagesections_Sections_Hero":
              return <Hero key={index} {...section} />;
            case "WpPage_Pagesections_Sections_ContentWithImage":
              return <ContentWithImage key={index} {...section} />;
            case "WpPage_Pagesections_Sections_Slider":
              return <SliderSection key={index} {...section} />;
            case "WpPage_Pagesections_Sections_Testimonials":
              return <Testimonials key={index} {...section} />;
            case "WpPage_Pagesections_Sections_ContentWithSlider":
              return <ContentWithSlider key={index} {...section} />;
            default:
              return <p key={index}>No section available</p>;
          }
        })}
        <PageLinks links={page.pageLinks} />
      </main>
    </Layout>
  );
};

export default IndexPage;
